import { useNavigation } from '@react-navigation/native';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CheckBox from '@react-native-community/checkbox';
import { FlatList, TouchableOpacity, View, Button, Linking } from 'react-native';
import { Card } from 'react-native-elements';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MainContainer from '../../../components/containers/MainContainer';
import UIText from '../../../components/elements/UIText';
import UIButton from '../../../components/forms/buttons/UIButton';
import { createPaymentFromCharges, getChargesAndPayments } from '../../../api/frontend-api-lib/src/services/payments.service';

const Charges = () => {
  const navigation = useNavigation();
  const [charges, setCharges] = useState([]);
  useEffect(() => {
    const getCharges = async () => {
      let charges = await getChargesAndPayments();
      let data = charges.data.charges.map((x) => { return { ...x, isChecked: false } })
      setCharges(data)
    };
    getCharges();
  }, []);

  const toggleCheckBox = (itemId) => {
    setCharges((prevData) =>
      prevData.map((item) =>
        item.ID === itemId ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  const payCharges = async () => {
    let payment = await createPaymentFromCharges({
      reference: 'Who knows',
      charges: charges.filter(x => x.isChecked ).map(x => x.airtableID)
    })

    Linking.openURL(payment.data.PaymentURL);
  }

  return (
    <>
      <MainContainer>
        <View style={styles.formContainer}>
          <View style={[styles.container]}>
            <UIText source={'h2'}>Outstanding Charges</UIText>
            <UIButton
              StartIcon={
                <MaterialIcons
                  name={'chevron-left'}
                  size={40}
                  style={{
                    color: 'black',
                  }}
                />
              }
              onPress={() => navigation.goBack()}>
            </UIButton>
            <FlatList
              data={charges}
              keyExtractor={item => item.ID}
              renderItem={({ item }) => {
                return (
                  <TouchableOpacity onPress={() => toggleCheckBox(item.ID)}>
                    <Card>
                      <View
                        style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                        <UIText source={'h4'}> {item.Summary}</UIText>
                        <>
                          <CheckBox value={item.isChecked} onValueChange={() => toggleCheckBox(item.ID)} />
                          <UIText> £{item['Total Charge']}</UIText>
                          <UIText>
                            Due Date: {moment(item['Date Created']).format('Do MMM YYYY')}
                          </UIText>
                        </>
                      </View>
                    </Card>
                  </TouchableOpacity>
                );
              }}
            />

         
          </View>
          <Button
            onPress={payCharges}
            title="Pay Selected Charges"
          />
        </View>
      </MainContainer>
    </>
  );
};
const styles = {
  container: { flex: 1, alignItems: 'center' },
  form: {},
  formContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: '#E5E5E5',
    width: '90%',
    borderRadius: 20,
    marginTop: 15,
    paddingVertical: 10,
    marginBottom: 20,
  },
};
export default Charges;
