import { color } from '../../color';
import { Dimensions } from 'react-native';
let width = Dimensions.get('window').width;

const UITextInput = {
  borderColor: color.white,
  borderWidth: 1,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  icon: {
    position: 'absolute',
    right: 10,
  },
  auth: {
    borderRadius: 11,
    paddingHorizontal: 20,
    height: 50,
    width: width * 0.7,
    backgroundColor: '#ffffff',
    color: 'grey',
    fontFamily: 'Arial',
    textAlign: 'center',
    placeholder: { color: color.black },
  },
  search: {
    borderRadius: 11,
    paddingHorizontal: 20,
    height: 50,
    width: width * 0.5,
    backgroundColor: '#ffffff',
    color: color.black,
    fontFamily: 'Arial',
    fontWeight: 'bold',
    placeholder: { color: color.black },
    searchIcon: {
      position: 'absolute',
      left: 10,
    },
    elevation: 12,
  },
};
export default UITextInput;
