import React, { useContext, useEffect, useState } from 'react';
import Toast from 'react-native-toast-message';

import AsyncStorage from '@react-native-async-storage/async-storage';
import { AuthContext } from './AuthContext';
import { getOfflineData, storeOfflineData } from '../utils/handleOffline';

export const RequestContext = React.createContext();

export const getArticles = async (limit, offset, request, setRequest) => {
  if (request.connected) {
    if (offset < 1)
      setRequest((prev) => ({ ...prev, articles: { ...prev.articles, loading: true } }));
    const res = await articles(limit, offset);
    await storeOfflineData('articles', [...request.articles.list, ...res?.data?.data]);
    setRequest((prev) => ({
      ...prev,
      articles: {
        ...prev?.articles,
        list: [...prev?.articles?.list, ...res?.data?.data],
        loading: false,
      },
    }));
  } else {
    let articles = await getOfflineData('articles');
    setRequest((prev) => ({
      ...prev,
      articles: {
        ...prev?.articles,
        list: articles,
        loading: false,
      },
    }));
  }
};
export const getIssues = async (request, setRequest) => {
  setRequest((prev) => ({ ...prev, issues: { ...prev?.issues, loading: true } }));
  if (request.connected) {
    const res = await getVolume({});
    setRequest((prev) => ({
      ...prev,
      issues: { ...prev?.issues, list: res?.data?.data, loading: false },
    }));
  } else {
    let issues = await getOfflineData('issues');
    setRequest((prev) => ({
      ...prev,
      issues: { ...prev?.issues, list: issues, loading: false },
    }));
  }
};
export const getCollections = async (request, setRequest) => {
  setRequest((prev) => ({
    ...prev,
    collections: { ...prev?.collections, loading: true },
  }));
  if (request.connected) {
    const collections = await collectionList({}, await AsyncStorage.getItem('email'));
    await storeOfflineData('collections', collections?.data?.data);
    setRequest((prev) => ({
      ...prev,
      collections: { list: [...collections?.data?.data], loading: false },
    }));
  } else {
    let collections = await getOfflineData('collections');
    if (collections !== null) {
      setRequest((prev) => ({
        ...prev,
        collections: { list: collections, loading: false },
      }));
    }
  }
};
export const RequestProvider = ({ children }) => {
  const [request, setRequest] = useState({
    loading: false,
    loadingAlt: false,
    collections: {
      list: [],
      loading: true,
    },
    issues: {
      list: [],
      loading: true,
    },
    articles: {
      list: [],
      loading: true,
    },
    connected: false,
  });
  const { auth } = useContext(AuthContext);
  const showToast = (toast) => {
    Toast.show(toast);
  };
  useEffect(() => {
    const collections = async (data: object) => {
      await getCollections(request, setRequest);
    };
    if (auth?.status) collections();
  }, []);
  return (
    <RequestContext.Provider value={{ request, setRequest, showToast }}>
      {children}
    </RequestContext.Provider>
  );
};
