import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getOrganisationId, setOrganisation } from './services/organisation.service';
import { getSiteId } from './services/site.service';
import { defaultApiUrl } from './config/http.config';
import AsyncStorage from '@react-native-async-storage/async-storage';

let emailAuthToken: string | null = null
let token: string | null = null;
let API_URL: string = defaultApiUrl;

function setApiUrl(url: string) {
  API_URL = url;
}

export function setToken(newToken: string) {
  token = newToken;
}

export function getEmailAuthToken() {
  return emailAuthToken
}

async function getDefaultParams() {
  const orgId = await AsyncStorage.getItem('organisationId');
  const siteId = getSiteId();
  return {
    orgId: orgId || null,
    siteId: siteId || null,
  };
}
const authInstance = (method: string, endpoint: string, params?: object, body?: object) => {
  return axios.create({
    baseURL: API_URL,
    url: endpoint,
    timeout: 10000,
    method,
    data: { ...body },
    params: {
      ...params,
    },
    withCredentials: true,
  })
};

const axiosInstance = async () => {
  const instance: any = axios.create({
    baseURL: API_URL,
    timeout: 10000,
    headers: {
      Authorization: token,
    },
    params: { ...(await getDefaultParams()) },
    withCredentials: true,
  });
  // instance.interceptors.request.use(async (req: AxiosRequestConfig) => {
  //   // const token: {exp: number} = await jwtDecode(`${getAPIToken()}`)
  //   // const isExpired = dayjs.unix(token.exp).diff(dayjs()) < 1
  //   // if (!isExpired) return req
  //   // const res = await refresh()
  //   // req.headers.Authorization = `${res.data.token}`
  //   return req;
  // });
  return instance;
};

async function accessTokenLogin(token: string) {
   try {
     let res = await axios.get(`${API_URL}authentication/accesstoken`, { params: { token } });
     token = res.data.token;
     console.debug(res.data);
     await setOrganisation(res.data.userOrganisations[0].id);
     await AsyncStorage.setItem('token', token);
     return res
   } catch (e) {
     return e;
   } 
}

async function createPortalUser(username: string, password: string) {
  try {
    return authInstance('POST', 'user/transatlantic/usersignup', {}, { username, password });
  } catch (e) {
    return e;
  }
}
async function changeOrganisation(orgId: string) {
  await setOrganisation(orgId);
}

export async function emailLogin(email: string) {
  try {
    let res = await axios.post(`${API_URL}authentication/email/login`, { email })
    emailAuthToken = res.data.twoFAToken;
    return true;
  } catch (e) {
    console.debug(e)
    return false;
  }
}

export async function emailLoginCodeSubmit(code: string) {
  try {
    let res = await axios.post(`${API_URL}authentication/2fa/${emailAuthToken}`, { code })
    emailAuthToken = res.data.twoFAToken;
    token = res.data.token;
    console.debug(res.data)
    await setOrganisation(res.data.userOrganisations[0].id);
    await AsyncStorage.setItem('token', token);
    return res;
  } catch (e) {
    return e;
  } 
}

async function login(username: string, password: string) {
  try {
    let res = authInstance(
      `POST`,
      'authentication/login',
      {},
      {
        username,
        password,
      }
    );
    return res;
  } catch (e) {
    return e;
  }
}

async function getAPIToken(): Promise<string> {
  token = await AsyncStorage.getItem('token');
  return token;
}

async function logout() {
  token = null;
}

async function resetPassword(email: string): Promise<AxiosResponse<object> | unknown> {
  try {
    return await authInstance('POST', 'authentication/request/password-reset', {}, { email });
  } catch (e) {
    return e;
  }
}

const reset = async (token: string, password: string) => {
  try {
    return await authInstance('POST', 'authentication/password-reset', {}, { token, password });
  } catch (e) {
    return e;
  }
};

export {
  createPortalUser,
  login,
  logout,
  resetPassword,
  getAPIToken,
  reset,
  changeOrganisation,
  setApiUrl,
  accessTokenLogin,
};

export default axiosInstance;
