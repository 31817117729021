import axios from 'axios';
import { sessionToken } from './user';
// import { setOrganisation } from './services/organisation.service';

let defaultApiUrl = 'https://stage-api-portal.ontheappt.cloud//';

export let API_URL: string = defaultApiUrl;

export function setApiUrl(url: string) {
  API_URL = url;
}

let token: string | null = null;

// function getDefaultParams() {
//   const orgId = getOrganisationId();
//   const siteId = getSiteId();
//   return {
//     ...(orgId ? { orgId } : null),
//     ...(siteId ? { siteId } : null),
//   };
// }
const getInstance = async (method: string, endpoint: string, params?: Object, body?: Object) => {
  return axios({
    baseURL: API_URL,
    url: endpoint,
    timeout: 10000,
    method,
    params: params,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};
const authInstance = async (method: string, endpoint: string, params?: Object, body?: Object) => {
  return axios({
    baseURL: API_URL,
    url: endpoint,
    timeout: 10000,
    method,
    data: body,
    params: params,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  });
};
const axiosInstance = async (method: string, endpoint: string, params?: Object, body?: Object) => {
  let token = await sessionToken();
  const instance: any = axios({
    baseURL: API_URL,
    url: endpoint,
    timeout: 10000,
    method,
    params: params,
    data: body,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      'X-CSRF-Token': token?.data,
    },
    withCredentials: true,
  });
  // instance.interceptors.request.use(async (req: AxiosRequestConfig) => {
  //   return req;
  // });

  return instance;
};

// async function createPortalUser(username: string, password: string) {
//   try {
//     return await authInstance('POST', 'user/transatlantic/usersignup', {}, { username, password });
//   } catch (e) {
//     return e;
//   }
// }
// function changeOrganisation(orgId: string) {
//   setOrganisation(orgId);
// }

// async function login(username: string, password: string) {
//   try {
//     let res = await authInstance(
//       `POST`,
//       'authentication/login',
//       {},
//       {
//         username,
//         password,
//       }
//     );
//     token = res.data.token;
//     setOrganisation(res.data.userOrganisations[0].id);
//     return res;
//   } catch (e) {
//     return e;
//   }
// }

function getAPIToken(): string | null {
  return token;
}

// async function refresh() {
//   try {
//     let res = await authInstance('GET', `authentication/refresh`);
//     token = res.data.token;
//     return res;
//   } catch (e) {
//     return e;
//   }
// }

async function logout() {
  token = null;
}

// async function resetPassword(email: string): Promise<AxiosResponse<object> | unknown> {
//   try {
//     return await authInstance('POST', 'authentication/request/password-reset', {}, { email });
//   } catch (e) {
//     return e;
//   }
// }

// const reset = async (token: string, password: string) => {
//   try {
//     return await authInstance('POST', 'authentication/password-reset', {}, { token, password });
//   } catch (e) {
//     return e;
//   }
// };

// const authenticateToken = async (params: Object, body: Object, token2fa: string) => {
//   try {
//     const res = await authInstance('POST', `authentication/2fa/${token2fa}`, params, body);
//     token = res.data.token;
//     setOrganisation(res.data.userOrganisations[0].id);
//     return res;
//   } catch (e) {
//     return e;
//   }
// };

export {
  // createPortalUser,
  // login,
  logout,
  // resetPassword,
  getAPIToken,
  // refresh,
  // reset,
  // changeOrganisation,
  authInstance,
  // authenticateToken,
  getInstance,
};

export default axiosInstance;
