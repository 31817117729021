import { Text } from 'react-native';

import React, { useContext } from 'react';
import { ThemeContext } from '../styles/ThemeContext';

const UIText = (props) => {
  const { source, style, children } = props;
  const { theme } = useContext(ThemeContext);
  const styles = {
    ...theme?.UIText,
    ...theme?.UIText?.[source],
    ...style,
  };

  return <Text style={styles}>{children}</Text>;
};

export default UIText;
