import axiosInstance from '../authentication';

async function GET(endpoint: string, params: object) {
  const api = await axiosInstance();
  return await api.get(endpoint, { params });
}

async function PATCH(endpoint: string, params?: Object, body?: Object) {
  const api = await axiosInstance();
  return api.patch(endpoint, body, { params });
}

async function UPDATE(endpoint: string, params: Object, body: Object) {
  const api = await axiosInstance();
  return api.patch(endpoint, { params, body });
}

async function POST(endpoint: string, params?: Object, body?: Object) {
  const api = await axiosInstance();
  return api.post(endpoint, body, { params });
}

async function POST_FILE(endpoint: string, params?: Object, body?: Object) {
  const api = await axiosInstance();
  api.defaults.headers.common['content-type'] = 'multipart/form-data';
  return api.post(endpoint, body, { params });
}

async function DELETE(endpoint: string, params: Object) {
  const api = await axiosInstance();
  return api.delete(endpoint, { params });
}

export { GET, PATCH, UPDATE, POST, POST_FILE, DELETE };
