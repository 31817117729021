import { AxiosResponse } from 'axios';
import { GET, POST } from './http.service';

export async function getProducts(): Promise<AxiosResponse | unknown> {
  try {
    return await GET('/product', {});
  } catch (e) {
    return e;
  }
}

export async function buyProducts(ids: string[]): Promise<AxiosResponse | unknown> {
  try {
    return await POST('/airtable/buy/pruducts', {}, {
      productIDS: ids
    });
  } catch (e) {
    return e;
  }
}

