import {Dimensions} from "react-native";
let width = Dimensions.get('window').width;

const ArticleTile = {
        backgroundColor: '#E9EEF4',
        marginHorizontal: 7,
        justifyContent: 'space-between',
        width: width / 2.4,
        minHeight: 250,
        image: {
            width: '100%',
            height: 150,
            resizeMode: 'stretch',
        },
        icon: {
            position: 'absolute',
            top: 0,
            alignSelf: 'flex-end',
            padding: 10,
            starred: {
                color: 'yellow',
            },
            unStarred: {
                color: 'white',
            },

    },
}

export default ArticleTile;
