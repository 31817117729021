import { AxiosResponse } from 'axios';
import { DELETE, GET, PATCH, POST, POST_FILE } from './http.service';
const organisationIdName = 'organisationId';
import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getOrganisationId(): string | null {
  let test = await AsyncStorage.getItem(organisationIdName);
  return test;
}

export async function setOrganisation(id: string) {
  await AsyncStorage.setItem(organisationIdName, id);
}

export async function addUserToOrg(userId: string): Promise<AxiosResponse | unknown> {
  try {
    return await POST('roleuser', {}, { userId });
  } catch (e) {
    return e;
  }
}

export async function getOrgs(): Promise<AxiosResponse | unknown> {
  try {
    return await GET('organisation', {});
  } catch (e) {
    return e;
  }
}

export async function addOrg(data: Object): Promise<AxiosResponse | unknown> {
  try {
    return await POST('organisation/transatlantic', {}, data);
  } catch (e) {
    return e;
  }
}

export async function getOrgByUser(id: string): Promise<AxiosResponse | unknown> {
  try {
    return await POST(`organisation/transatlantic/${id}`);
  } catch (e) {
    return e;
  }
}

export async function deleteOrgUser(id: string): Promise<AxiosResponse | unknown> {
  try {
    return await DELETE(`organisation/user/${id}`, {});
  } catch (e) {
    return e;
  }
}

export async function getSingleOrg(id: string): Promise<AxiosResponse | unknown> {
  try {
    return await GET(`organisation/${id}`, {});
  } catch (e) {
    return e;
  }
}

export async function updateSingleOrg(id: string, data: Object): Promise<AxiosResponse | unknown> {
  try {
    return await PATCH(`organisation/${id}`, {}, data);
  } catch (e) {
    return e;
  }
}

export async function replaceSingleOrg(id: string, data: Object): Promise<AxiosResponse | unknown> {
  try {
    return await PATCH(`organisation/${id}`, {}, data);
  } catch (e) {
    return e;
  }
}

export async function deleteSingleOrg(id: string): Promise<AxiosResponse | unknown> {
  try {
    let org = getOrganisationId();
    setOrganisation(id);
    let ret = await DELETE(`organisation/${id}`, {});
    setOrganisation(org);
    return ret;
  } catch (e) {
    return e;
  }
}

export async function getMultipleOrgs(): Promise<AxiosResponse | unknown> {
  try {
    return await GET(`organisation`, {});
  } catch (e) {
    return e;
  }
}

export async function createSingleOrg(data: Object): Promise<AxiosResponse | unknown> {
  try {
    return await POST(`organisation`, {}, data);
  } catch (e) {
    return e;
  }
}

export async function addOrgUser(id: string, data: Object): Promise<AxiosResponse | unknown> {
  try {
    return await PATCH(`organisation/${id}`, {}, data);
  } catch (e) {
    return e;
  }
}

export async function getOrgContractors(): Promise<AxiosResponse | unknown> {
  try {
    return await GET(`organisationcontractor`, {});
  } catch (e) {
    return e;
  }
}

export async function uploadLogo(file: any, orgId: string): Promise<AxiosResponse | unknown> {
  let org = getOrganisationId();
  setOrganisation(orgId);
  try {
    let ret = await POST_FILE(`organisation/uploadlogo`, {}, file);
    setOrganisation(org);
    return ret;
  } catch (e) {
    setOrganisation(org);
    return e;
  }
}

export async function updateTransatOrganisation(data: Object) {
  return await PATCH(`/organisation/transatlantic`, {}, data);
}

export async function getClientData(orgId: string): Promise<AxiosResponse | unknown> {
  let org = getOrganisationId();
  setOrganisation(orgId);
  try {
    let ret = await GET(`organisation/clientdata`, {});
    setOrganisation(org);
    return ret;
  } catch (e) {
    setOrganisation(org);
    return e;
  }
}

export async function assignContractor(
  id: string,
  userId: string
): Promise<AxiosResponse | unknown> {
  try {
    return await POST(`organisationcontractor/assign/${id}`, {}, { userId });
  } catch (e) {
    return e;
  }
}
