import UIButton from './components/forms/UIButton';
import UIText from './components/elements/UIText';
import UITextInput from './components/forms/UITextInput';
import ArticleTile from './components/elements/tile/ArticleTile';
import IssueTile from './components/elements/tile/IssueTile';
import Container from './components/containers/Container';
import Modal from './components/elements/Modal';
import Dropdown from './components/forms/Dropdown';

const defaultTheme = () => {
  // Shift and Left click below to go to the definition of the UI component styles
  return {
    UIText,
    UIButton,
    UITextInput,
    ArticleTile,
    IssueTile,
    Container,
    Dropdown,
    Modal,
  };
};

export default defaultTheme;
