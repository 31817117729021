import React, { useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';

const Loading = () => {
  const animationRef = useRef(null);
  useEffect(() => {
    animationRef.current?.play();
  }, []);

  return <View style={styles.animationContainer}></View>;
};

export default Loading;

const styles = StyleSheet.create({
  animationContainer: {
    backgroundColor: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
});
