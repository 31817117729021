import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Button, FlatList, Image, Linking, TouchableOpacity, View } from 'react-native';
import { Card } from 'react-native-elements';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { buyProducts, getProducts } from '../../api/frontend-api-lib/src/services/product.service';
import MainContainer from '../../components/containers/MainContainer';
import UIText from '../../components/elements/UIText';
import UIButton from '../../components/forms/buttons/UIButton';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Product } from './Product.type';
import uuid from 'react-native-uuid';

const Basket = () => {
  const navigation = useNavigation();
  const [products, setProducts] = useState<Product[]>([]);
  useEffect(() => {
    update()
  }, []);

  const update = async () => {
    (async () => {
      let products = await AsyncStorage.getItem('basket')
      if (products) {
        setProducts(JSON.parse(products))
      }
    })() 
  }

  const buyBasket = async () => {
    if (products) {
      let payment = await buyProducts(products.map(x => x.id))
      Linking.openURL(payment.data.PaymentURL);
    }
  }

  const clearBasket = async () => {
    await AsyncStorage.removeItem('basket')
    setProducts([])
  }

  return (
    <>
      <MainContainer>
        <View style={styles.formContainer}>
          <UIText source={'h2'}>Basket</UIText>
          <UIButton
            StartIcon={
              <MaterialIcons
                name={'chevron-left'}
                size={40}
                style={{
                  color: 'black',
                }}
              />
            }
            onPress={() => navigation.goBack()}>
          </UIButton>
          <Button
            onPress={() => { update() }}
            title="Update"
          />
          <Button
            onPress={async () => { await buyBasket() }}
            title="Buy All"
          />
          <Button
            onPress={() => { clearBasket() }}
            title="Clear"
          />
          <FlatList
            data={products}
            scrollEnabled={true}
            keyExtractor={() => uuid.v4() as string }
            renderItem={({ item }) => {
              return (
                <TouchableOpacity>
                  <Card>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>

                      <UIText source={'h4'}> {item.displayName}</UIText>
                      <Image source={{ uri: item.featuredimage }} style={{
                        height: 135,
                        width: 155
                      }} />
                      <>
                        <UIText>{item.description}</UIText>
                        <UIText>£{item.price}</UIText>
                      </>
                    </View>
                  </Card>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </MainContainer>
    </>
  );
};
const styles = {
  container: { flex: 1, alignItems: 'center' },
  form: {},
  formContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: '#E5E5E5',
    width: '90%',
    borderRadius: 20,
    marginTop: 15,
    paddingVertical: 10,
    marginBottom: 20,
  },
};
export default Basket;
