import 'react-native-gesture-handler';
import AppChild from './AppChild';
import { RequestProvider } from './src/context/RequestContext';
import { AuthProvider } from './src/context/AuthContext';
import { ThemeProvider } from './src/components/styles/ThemeContext';
import {  NavigationContainer } from '@react-navigation/native';
import Toast from 'react-native-toast-message';
import { PDFProvider } from './src/context/PDFContext';
import { SearchProvider } from './src/context/SearchContext';

const linking = {
  prefixes: [],
  config: {
    screens: {
      AccessToken: 'access-token/login/:token',
    },
  },
};

function App() {
  return (
    <NavigationContainer linking={linking} fallback={<p>Loading...</p>}>
      <ThemeProvider>
        <RequestProvider>
          <AuthProvider>
            <SearchProvider>
              <PDFProvider>
                <AppChild />
                <Toast position="bottom" bottomOffset={20} />
              </PDFProvider>
            </SearchProvider>
          </AuthProvider>
        </RequestProvider>
      </ThemeProvider>
    </NavigationContainer>
  );
}

export default App;
