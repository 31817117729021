export const color = {
  primary: '#D46490',
  secondary: '#E5D060',
  tertiary: '#6EAC90',
  primaryHover: '#1A3C6C',
  secondaryHover: '#3A5F8F',
  background: '#F2F2F2',
  //
  white: '#ffffff',
  black: '#000000',
};
