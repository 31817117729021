const siteIdName = 'siteId'
import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getSiteId() : string | null {
		return null 
}

export async function setSite(id: string) {
	await AsyncStorage.setItem(siteIdName, id)

}
