import React, {useContext} from 'react';
import {Dimensions, ScrollView} from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import {ThemeContext} from "../styles/ThemeContext";

const Container = (props) => {
  const { children, style, source } = props;
    const theme = useContext(ThemeContext)
  const styles = {
      ...theme?.Container,
      ...theme?.Container?.[source],
      ...style
  }
  return (
    <GestureHandlerRootView >
      <ScrollView style={styles.container} nestedScrollEnabled={true}  alwaysBounceVertical={true} bounces={true}>{children}</ScrollView>
    </GestureHandlerRootView>
  );
};

export default Container;
