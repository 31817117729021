import {Dimensions} from "react-native";

const Modal = {
    modal: {
        collection: {
            centeredView: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            },
            modalView: {
                backgroundColor: '#ffffff',
                padding: 22,
                alignItems: 'center',
                boxShadow: 'none',
                shadowColor: 'transparent',
                width: Dimensions.get('window').width * 0.8,
                borderRadius: 10,
            },
            button: {
                borderRadius: 20,
                padding: 10,
                elevation: 2,
            },
            buttonOpen: {
                backgroundColor: '#F194FF',
            },
            buttonClose: {
                backgroundColor: '#2196F3',
            },
            textStyle: {
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
            },
            modalText: {
                marginBottom: 15,
                textAlign: 'center',
            },
        },
        search: {
            centeredView: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            },
            modalView: {
                backgroundColor: '#ffffff',
                padding: 22,
                alignItems: 'center',
                boxShadow: 'none',
                shadowColor: 'transparent',
                width: Dimensions.get('window').width * 0.8,
                borderRadius: 10,
            },
            button: {
                borderRadius: 20,
                padding: 10,
                elevation: 2,
            },
            buttonOpen: {
                backgroundColor: '#F194FF',
            },
            buttonClose: {
                backgroundColor: '#2196F3',
            },
            textStyle: {
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
            },
            modalText: {
                marginBottom: 15,
                textAlign: 'center',
            },
        },
    },
}

export default Modal
