import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Button, FlatList, Image, Linking, TouchableOpacity, View } from 'react-native';
import { Card } from 'react-native-elements';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { buyProducts, getProducts } from '../../api/frontend-api-lib/src/services/product.service';
import MainContainer from '../../components/containers/MainContainer';
import UIText from '../../components/elements/UIText';
import UIButton from '../../components/forms/buttons/UIButton';
import { Product } from './Product.type';

const Products = () => {
  const navigation = useNavigation();
  const [products, setProducts] = useState<Product[]>([]);
  useEffect(() => {
    (async () => {
      let products = (await getProducts()) as { data: Product[] };
      setProducts(products?.data)
    })()
  }, []);

  const buyNow = async (id: string) => {
    let payment = await buyProducts([id])
    Linking.openURL(payment.data.PaymentURL);
  }

  const addToBasket = async (product: Product) => {
    try {
      let theBasket = await AsyncStorage.getItem('basket')
      if (theBasket) {
        let parsed = JSON.parse(theBasket) as Product[]
        parsed.push(product)
        await AsyncStorage.setItem('basket', JSON.stringify(parsed))
      } else {
        await AsyncStorage.setItem('basket', JSON.stringify([product]))
      }
    } catch (err) {
      console.log((await AsyncStorage.getItem('basket')))
      console.log(err)
    }
  }

  return (
    <>
      <MainContainer>
        <View style={styles.formContainer}>
          <UIText source={'h2'}>Products</UIText>
          <UIButton
            StartIcon={
              <MaterialIcons
                name={'chevron-left'}
                size={40}
                style={{
                  color: 'black',
                }}
              />
            }
            onPress={() => navigation.goBack()}>
          </UIButton>
          <FlatList
            data={products}
            scrollEnabled={true}
            keyExtractor={item => item.id}
            renderItem={({ item }) => {
              return (
                <TouchableOpacity>
                  <Card>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>

                      <UIText source={'h4'}> {item.displayName}</UIText>
                      <Image source={{ uri: item.featuredimage }} style={{
                        height: 135,
                        width: 155
                      }} />
                      <>
                        <UIText>{item.description}</UIText>
                        <UIText>£{item.price}</UIText>
                        <Button
                          onPress={() => { buyNow(item.id) }}
                          title="Buy Now"
                        />
                        <Button
                          onPress={() => { addToBasket(item) }}
                          title="Add To Basket"
                        />
                      </>
                    </View>
                  </Card>
                </TouchableOpacity>
              );
            }}
          />
        </View>
      </MainContainer>
    </>
  );
};
const styles = {
  container: { flex: 1, alignItems: 'center' },
  form: {},
  formContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: '#E5E5E5',
    width: '90%',
    borderRadius: 20,
    marginTop: 15,
    paddingVertical: 10,
    marginBottom: 20,
  },
};
export default Products;
