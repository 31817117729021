const Dropdown = {
  backgroundColor: 'white',
  fontSize: 16,
  paddingVertical: 12,
  paddingHorizontal: 10,
  borderWidth: 1,
  borderColor: 'red', // Border color
  color: 'black',
  paddingRight: 30, //
  borderRadius: 11,
};
export default Dropdown;
