import { TextInput, View } from 'react-native';
import React, { useContext } from 'react';
import { ThemeContext } from '../styles/ThemeContext';
import UIText from '../elements/UIText';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
const UITextInput = (props) => {
  const { source, style, label, password, search, children, ...rest } = props;
  const { theme } = useContext(ThemeContext);
  const [showPass, setShowPass] = React.useState(false);
  const styles = {
    ...theme?.UITextInput,
    ...theme?.UITextInput?.[source],
    ...style,
    container: {
      ...theme?.UITextInput?.container,
      ...theme?.UITextInput?.[source]?.container,
      ...style?.container,
    },
    label: {
      ...theme?.UITextInput?.label,
      ...theme?.UITextInput?.[source]?.label,
      ...style?.label,
    },
  };
  return (
    <View style={styles.container}>
      <UIText source={'label'} style={styles.label}>
        {label}
      </UIText>
      <View style={styles}>
        {search ? (
          <FontAwesomeIcon
            name={'search'}
            style={styles.searchIcon}
            size={30}
            onPress={() => setShowPass(false)}
          />
        ) : (
          <></>
        )}
        <TextInput
          placeholderTextColor={'#000000'}
          {...rest}
          // style={styles.input}
          // onChangeText={onChangeNumber}
          // value={number}
          // placeholder="useless placeholder"
          // keyboardType="numeric"
          secureTextEntry={password && !showPass}
        />
        {password ? (
          <View style={styles.icon}>
            {showPass ? (
              <FontAwesomeIcon
                name={'eye'}
                style={{ color: 'black' }}
                size={30}
                onPress={() => setShowPass(false)}
              />
            ) : (
              <FontAwesomeIcon
                name={'eye-slash'}
                style={{ color: 'black' }}
                size={30}
                onPress={() => setShowPass(true)}
              />
            )}
          </View>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

export default UITextInput;
