import {Dimensions, View} from "react-native";
import Container from "./Container";

const AuthContainer = (props: Object) => {
    const {children} = props;
    return (
        <Container>
       <View style={styles.container}>{children}</View>
        </Container>
    );
};

export default AuthContainer;

const styles = {
    container:{
        flex: 1,
        minHeight: Dimensions.get('window').height ,
        backgroundColor: '#fff'
    }
}
