import * as React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from '../screens/auth/web/Login';
import SignUpScreen from '../screens/auth/web/SignUp';

const Stack = createNativeStackNavigator();

const AuthStack = () => {
  return (
    <Stack.Navigator
      initialRouteName={'Login'}
      screenOptions={{
        headerShown: false,
      }}>
      {/*<Stack.Screen name="Landing" component={LandingScreen} />*/}

      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="SignUp" component={SignUpScreen} />
      <Stack.Screen name="AccessToken" component={LoginScreen} />
      {/*<AuthStackNative.Screen name="Profile" component={ProfileScreen} />*/}
    </Stack.Navigator>
  );
};
export default AuthStack;
