import React, { useState } from 'react';

export const PDFContext = React.createContext({});
export const PDFProvider = ({ children }) => {
  const [pdf, setPdf] = useState({
    pdfOpen: false,
    id: null,
    uri: '',
    title: '',
    date: '',
  });
  return <PDFContext.Provider value={{ pdf, setPdf }}>{children}</PDFContext.Provider>;
};
