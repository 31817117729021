import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Image, View } from 'react-native';
import Basket from '../screens/main/Basket';
import Products from '../screens/main/Products';
import Charges from '../screens/main/charges/Charges';

const Tab = createBottomTabNavigator();

export function TabNav() {
  return (
    <>
    <View style={{ flexDirection: 'row', justifyContent: 'space-between', height: 80 }}>
      <Image
        source={require('../../assets/splash.png')}
        style={{
          width: 100,
          height: 130,
          resizeMode: 'contain',
          marginTop: -20,
          marginLeft: 10,
        }}
      />
    </View>
      <Tab.Navigator>
        <Tab.Screen name="Charges" component={Charges} />
        <Tab.Screen name="Products" component={Products} />
        <Tab.Screen name="Basket" component={Basket} />
      </Tab.Navigator>
    </>
  );
}