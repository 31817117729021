import { Dimensions, Image, View } from 'react-native';
import UIText from '../../../components/elements/UIText';
import UITextInput from '../../../components/forms/UITextInput';
import React, { useContext, useState } from 'react';
import UIButton from '../../../components/forms/buttons/UIButton';
import { RequestContext } from '../../../context/RequestContext';
import { ThemeContext } from '../../../components/styles/ThemeContext';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import AuthContainer from '../../../components/containers/AuthContainer';
import { useNavigation } from '@react-navigation/native';
import Checkbox from 'expo-checkbox';

const SignUpScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsSelected, setSelectedTerms] = useState(false);
  const { request } = useContext(RequestContext);
  const { theme } = useContext(ThemeContext);
  const navigation = useNavigation();

  const handleSignup = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        console.log(userCredential);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  return (
    <AuthContainer>
      <View style={styles.formContainer}>
        <Image
          source={require('../../../../assets/splash.png')}
          style={{
            width: 180,
            height: 230,
            resizeMode: 'contain',
            alignSelf: 'center',
            position: 'absolute',
            top: -130,
          }}
        />
        <View style={[styles.container, theme.container]}>
          <UITextInput
            onChangeText={setEmail}
            value={email}
            placeholder="first name"
            keyboardType="email-address"
            source={'auth'}
          />
          <UITextInput
            onChangeText={setPassword}
            value={password}
            placeholder="last name"
            keyboardType="default"
            source={'auth'}
          />
          <UITextInput
            onChangeText={setEmail}
            value={email}
            placeholder="email"
            keyboardType="email-address"
            source={'auth'}
          />
          <UITextInput
            onChangeText={setPassword}
            value={password}
            placeholder="password"
            keyboardType="default"
            source={'auth'}
          />
          <UIButton
            source={'auth'}
            style={{ marginTop: 10 }}
            onPress={handleSignup}
            disabled={request.loading}>
            register
          </UIButton>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <UIText source={'label'} style={{ color: 'grey', marginTop: 10 }}>
              I agree to the{' '}
              <UIText
                source={'label'}
                style={{ color: 'black', textDecorationLine: 'underline', marginTop: 10 }}>
                Terms & Conditions
              </UIText>
            </UIText>
            <Checkbox
              value={termsSelected}
              onValueChange={setSelectedTerms}
              style={{
                marginTop: 12,
                marginLeft: 10,
                backgroundColor: 'white',
                borderColor: 'white',
                width: 35,
                height: 35,
                borderRadius: 10,
              }}
            />
          </View>
          <UIButton
            onPress={() => navigation.navigate('Login')}
            source={'link'}
            style={{ marginTop: 20 }}>
            login
          </UIButton>
          {/*<UIButton*/}
          {/*  onPress={() => Linking.openURL('https://flytt.co.uk/user/password')}*/}
          {/*  source={'link'}*/}
          {/*  style={{ marginTop: 20 }}>*/}
          {/*  I&apos;ve forgotten my password*/}
          {/*</UIButton>*/}
        </View>
      </View>
    </AuthContainer>
  );
};

const height = Dimensions.get('window').height;
const styles = {
  container: { flex: 1, alignItems: 'center', paddingTop: 50 },
  form: {},
  formContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: '#E5E5E5',
    width: '90%',
    borderRadius: 20,
    marginTop: '30%',
    height: '70%',
  },
};

export default SignUpScreen;
