import React, { useState } from 'react';

export const SearchContext = React.createContext({});
export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState({
    open: false,
    term: '',
  });

  return <SearchContext.Provider value={{ search, setSearch }}>{children}</SearchContext.Provider>;
};
