import { color } from '../../color';
import { Dimensions } from 'react-native';
let width = Dimensions.get('window').width;
const UIButton = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 11,
  backgroundColor: '#ffffff',
  borderWidth: 1,
  text: {
    color: color.black,
  },
  auth: {
    borderColor: color.secondary,
    paddingHorizontal: 20,
    height: 50,
    backgroundColor: color.secondary,
    container: {
      paddingTop: 10,
      width: width * 0.7,
    },
    button: {
      alignItems: 'center',
    },
    text: {
      justifySelf: 'center',
      marginLeft: '55%',
      color: color.white,
    },
    icon: {
      color: color.black,
    },
  },
  search: {
    height: 50,
    container: {
      paddingTop: 10,
      backgroundColor: '#fffff',
      paddingHorizontal: 20,
    },
    text: {
      color: '#ffffff',
    },
    icon: {
      color: '#ffffff',
    },
  },
  list: {
    paddingHorizontal: 20,
    height: 50,
    borderColor: color.white,
    justifyContent: 'space-between',
    container: {
      paddingTop: 10,
      width: width * 0.8,
      backgroundColor: '#fffff',
    },
    text: {
      color: color.black,
      marginLeft: 10,
    },
    startIcon: {
      backgroundColor: color.primary,
      width: 50,
      height: 50,
      borderTopLeftRadius: 11,
      borderBottomLeftRadius: 11,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: -20,
    },
  },
  issueButton: {
    // backgroundColor: '#ffffff',
    // width: 20,
    // text: {
    //   color: '#ffffff',
    //   fontSize: 5,
    // },
  },
  backButton: {
    borderWidth: 0,
    backgroundColor: '#ABABAB',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    text: {
      fontWeight: 'bold',
      fontSize: 18,
    },
  },
  link: {
    paddingHorizontal: 20,
    borderWidth: 0,
    justifyContent: 'center',
    backgroundColor: 'none',

    text: {
      textDecorationLine: 'underline',

      color: 'grey',
    },
  },
  menu: {
    text: {
      color: color.black,
      fontSize: 25,
    },
    borderWidth: 0,
    borderRadius: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    backgroundColor: 'transparent',
  },
};
export default UIButton;
