import {Dimensions} from "react-native";

let width = Dimensions.get('window').width;

const IssueTile = {
        backgroundColor: '#E9EEF4',
        justifyContent: 'space-between',
        width: width / 2.4,
        marginHorizontal: 7,
        minHeight: 270,
        height: '100%',
        image: {
            width: '100%',
            height: 275,
            resizeMode: 'stretch',
        },
        icon: {
            position: 'absolute',
            top: 0,
            alignSelf: 'flex-end',
            padding: 10,
        },
}
export default IssueTile;
