import { Dimensions } from 'react-native';

let width = Dimensions.get('window').width;
const UIText = {
  fontFamily: 'roboto-medium',
  fontStyle: 'normal',
  textTransform: 'none',
  color: 'black',
  h1: {
    fontSize: 40,
    spacing: 0,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: 30,
    spacing: 0,
  },
  h3: {
    fontSize: 30,
    spacing: 0,
    fontWeight: 'bold',
    fontFamily: 'roboto-light',
  },
  h4: {
    fontSize: 20,
  },
  h5: {
    fontSize: 15,
    fontWeight: 'bold',
    spacing: 0,
  },
  h6: {
    fontSize: 10,
    fontWeight: 'bold',
    spacing: 0,
  },
  body: {
    fontSize: 15,
    fontWeight: 0,
    spacing: 0,
    wordWrap: 'break-word',
    textWrap: 'balance',
    width: width * 0.4,
  },
  subtitle: {
    fontSize: 18,
    spacing: 0,
  },
  subtitle2: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  label: {
    fontWeight: 'bold',
  },
  button: {
    textAlign: 'center',
  },
  tileDate: {
    fontWeight: 'bold',
  },
  tileText: {
    fontSize: 18,
    height: 'auto',
  },
  collectionTitle: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  collectionCount: {
    fontSize: 15,
  },
  nav: {
    fontSize: 10,
  },
  PDFView: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  link: {
    fontFamily: 'roboto-light',
  },
};
export default UIText;
