import React, { useContext, useEffect, useState } from 'react';
import { Dimensions, Image, View } from 'react-native';
import UITextInput from '../../../components/forms/UITextInput';
import UIButton from '../../../components/forms/buttons/UIButton';
// import { login, loginStatus } from '../../requests/user';
import { useNavigation } from '@react-navigation/native';
import {
  emailLogin,
  emailLoginCodeSubmit,
  getAPIToken,
  accessTokenLogin,
} from '../../../api/frontend-api-lib/src/authentication';
import AuthContainer from '../../../components/containers/AuthContainer';
import { ThemeContext } from '../../../components/styles/ThemeContext';
import { AuthContext } from '../../../context/AuthContext';
import { RequestContext } from '../../../context/RequestContext';

const LoginScreen = ({route}) => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const navigation = useNavigation();
  const { setAuth } = useContext(AuthContext);
  const { request, setRequest, showToast } = useContext(RequestContext);
  const { theme } = useContext(ThemeContext);
  const [secondAuthStage, setSecondAuthStage] = useState(false); 

  useEffect(() => {
    (async () => {
      if (route.params.token) {
        await accessTokenLogin(route.params.token);
      }
      let token = await getAPIToken();
      console.debug(token)
      if (token) setAuth((prev) => ({ ...prev, status: 1 }));
    })();
  }, [])

  const handleLogin = async () => {
    if (!secondAuthStage) {
      console.debug('ALEX')
      let success = await emailLogin(email)
      setSecondAuthStage(success)
      console.debug(success) 

      if (!success) {
        showToast({
          type: 'error',
          text1: 'Error',
          text2: 'Unable to login to Flytt',
        });
      } else {
        showToast({
          type: 'success',
          text1: 'Success',
          text2: 'Login Email Sent',
        });
      }
    } else {
      let res = await emailLoginCodeSubmit(code)
      if (res?.status !== 201) {
        showToast({
          type: 'error',
          text1: 'Error',
          text2: 'Unable to login to Flytt',
        });
        return;
      } else {
        setAuth((prev) => ({ ...prev, status: 1 }));
      }
    }
  };
  
  return (
    <AuthContainer>
      <View style={styles.formContainer}>
        <Image
          source={require('../../../../assets/splash.png')}
          style={{
            width: 180,
            height: 230,
            resizeMode: 'contain',
            alignSelf: 'center',
            position: 'absolute',
            top: -130,
          }}
        />
        {secondAuthStage ?

          <View style={[styles.container, theme.container]}>
            <UITextInput
              onChangeText={setCode}
              value={code}
              placeholder="Code"
              source={'auth'}
            />
            <UIButton
              source={'auth'}
              style={{ marginTop: 10 }}
              onPress={handleLogin}
              disabled={request.loading}>
              sign in
            </UIButton>
          </View>
          :
          <View style={[styles.container, theme.container]}>
            <UITextInput
              onChangeText={setEmail}
              value={email}
              placeholder="email"
              keyboardType="email-address"
              source={'auth'}
            />
            <UIButton
              source={'auth'}
              style={{ marginTop: 10 }}
              onPress={handleLogin}
              disabled={request.loading}>
              sign in
            </UIButton>
          </View>
        }
    
      </View>
    </AuthContainer>
  );
};

const height = Dimensions.get('window').height;
const styles = {
  container: { flex: 1, alignItems: 'center', paddingTop: height / 8 },
  form: {},
  formContainer: {
    alignSelf: 'center',
    justifySelf: 'center',
    backgroundColor: '#E5E5E5',
    width: '90%',
    borderRadius: 20,
    marginTop: '30%',
    height: '70%',
  },
};

export default LoginScreen;
