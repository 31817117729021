import { useFonts } from 'expo-font';
import * as NavigationBar from 'expo-navigation-bar';
import * as SystemUI from 'expo-system-ui';
import React, { useContext, useEffect } from 'react';
import { Platform, SafeAreaView, StatusBar, View } from 'react-native';
import 'react-native-gesture-handler';
import { setApiUrl } from './src/api/frontend-api-lib/src';
import Loading from './src/components/elements/loading/Loading';
import { ThemeContext } from './src/components/styles/ThemeContext';
import defaultTheme from './src/components/styles/defaultTheme';
import { AuthContext } from './src/context/AuthContext';
import { RequestContext } from './src/context/RequestContext';
import AuthStack from './src/navigation/AuthStack';
import { TabNav } from './src/navigation/TabNav';
import { startFirebase } from './src/utils/firebase.config';

function AppChild() {
  const { auth, setAuth } = useContext(AuthContext);
  const { request, showToast } = useContext(RequestContext);
  const { setTheme } = useContext(ThemeContext);
  const [fontsLoaded] = useFonts({
    'roboto-bold': require('./assets/fonts/Roboto/Roboto-Bold.ttf'),
    'roboto-bold-italic': require('./assets/fonts/Roboto/Roboto-BoldItalic.ttf'),
    'roboto-italic': require('./assets/fonts/Roboto/Roboto-Italic.ttf'),
    'roboto-light': require('./assets/fonts/Roboto/Roboto-Light.ttf'),
    'roboto-light-italic': require('./assets/fonts/Roboto/Roboto-LightItalic.ttf'),
    'roboto-medium': require('./assets/fonts/Roboto/Roboto-Medium.ttf'),
    'roboto-medium-italic': require('./assets/fonts/Roboto/Roboto-MediumItalic.ttf'),
    'roboto-thin': require('./assets/fonts/Roboto/Roboto-Thin.ttf'),
    'roboto-thin-italic': require('./assets/fonts/Roboto/Roboto-ThinItalic.ttf'),
    'roboto-regular': require('./assets/fonts/Roboto/Roboto-Regular.ttf'),
  });

  useEffect(() => {
    setApiUrl('https://stage-api-portal.ontheappt.cloud/');
    const theme = defaultTheme();
    setTheme(theme);
    startFirebase();
    if (Platform.OS !== 'ios') {
      NavigationBar.setBackgroundColorAsync('#ffffff');
    }
    SystemUI.setBackgroundColorAsync('#ffffff');
  }, []);

  if (!fontsLoaded) return <Loading />;
  return (
    <>
      <SafeAreaView style={{ flex: 1 }}>
        {auth?.status ? (
          <>
            <TabNav />
          </>
        ) : (
          <AuthStack />
        )}
      </SafeAreaView>
      <StatusBar style="auto" />
      {request.loading && (
        <View style={{ position: 'absolute', height: '100%', width: '100%' }}>
          <Loading />
        </View>
      )}
    </>
  );
}

export default AppChild;
