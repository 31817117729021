import React, { useContext } from 'react';
import { TouchableOpacity, View } from 'react-native';
import UIText from '../../elements/UIText';
import { ThemeContext } from '../../styles/ThemeContext';

const UIButton = (props) => {
  const { source, style, children, label, Icon, StartIcon, EndIcon, ...rest } = props;
  const { theme } = useContext(ThemeContext);
  const styles = {
    button: {
      ...theme?.UIButton,
      ...theme?.UIButton?.[source],
      ...style,
    },
    container: {
      ...theme?.UIButton?.container,
      ...theme?.UIButton?.[source]?.container,
      ...style?.container,
    },
    label: {
      ...theme?.UIButton?.label,
      ...theme?.UIButton?.[source]?.label,
      ...style?.label,
    },
    text: {
      ...theme?.UIButton?.text,
      ...theme?.UIButton?.[source]?.text,
      ...style?.text,
    },
    startIcon: {
      ...theme?.UIButton?.startIcon,
      ...theme?.UIButton?.[source]?.startIcon,
      ...style?.startIcon,
    },
    endIcon: {
      ...theme?.UIButton?.endIcon,
      ...theme?.UIButton?.[source]?.endIcon,
      ...style?.endIcon,
    },
  };
  return (
    <View style={styles.container}>
      {label ? (
        <UIText source={'label'} style={styles.label}>
          {label}
        </UIText>
      ) : (
        <></>
      )}
      <TouchableOpacity style={styles.button} {...rest}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={styles.startIcon}>{StartIcon ? StartIcon : <></>}</View>
          <UIText source={'button'} style={styles.text}>
            {children}
          </UIText>
        </View>
        <View style={styles.endIcon}>{EndIcon ? EndIcon : <></>}</View>
      </TouchableOpacity>
    </View>
  );
};

export default UIButton;
