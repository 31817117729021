import React, { useContext, useEffect, useState } from 'react';
import { loginStatus } from '../requests/user';
import { RequestContext } from './RequestContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const AuthContext = React.createContext({});
export const AuthProvider = ({ children }) => {
  const { request, setRequest } = useContext(RequestContext);
  const [auth, setAuth] = useState({
    loggedIn: false,
    sessionToken: null,
    status: 0,
    user: null,
    pdfOpen: false,
  });
  useEffect(() => {
    const checkStatus = async () => {
      if (request.connected) {
        setRequest((prev) => ({ ...prev, loading: false, loadingAlt: false }));
        const res2 = await loginStatus();
        setRequest((prev) => ({ ...prev, loading: false, loadingAlt: false }));
        const storeLogin = await getLogin('user');
        // setAuth((prev) => ({ ...prev, user: storeLogin, status: res2?.data }));
      }
    };
    checkStatus()
      .then()
      .catch((err) => console.log(err));
  }, []);
  const storeLogin = async (value) => {
    try {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('user', jsonValue);
    } catch (e) {}
  };
  const getLogin = async (key) => {
    try {
      const jsonValue = await AsyncStorage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {}
  };
  useEffect(() => {
    const handleLoginData = async () => {
      await storeLogin(auth.user);
    };
    handleLoginData();
    console.log(auth);
  }, [auth]);

  return <AuthContext.Provider value={{ auth, setAuth }}>{children}</AuthContext.Provider>;
};
