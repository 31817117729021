import { Dimensions, View } from 'react-native';

import Container from './Container';

const MainContainer = (props: Object) => {
  const { children } = props;
  return (
    <Container>
      <View style={styles.container}>{children}</View>
    </Container>
  );
};

export default MainContainer;

const styles = {
  container: {
    flex: 1,
    minHeight: Dimensions.get('window').height,
  },
};
