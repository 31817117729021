import { authInstance, getInstance } from './config';
import { AxiosResponse } from 'axios';

async function login(data: object): Promise<AxiosResponse<object> | unknown> {
  try {
    return await authInstance('POST', 'user/login?_format=json', {}, data);
  } catch (e) {
    console.log(e)
  }
}
async function loginStatus(): Promise<AxiosResponse<object> | unknown> {
  try {
    return await getInstance('GET', 'user/login_status?_format=json');
  } catch (e) {
    return e;
  }
}
async function sessionToken(): Promise<AxiosResponse<string> | string> {
  try {
    return await getInstance('GET', '/session/token');
  } catch (e) {
    return e;
  }
}

export { login, loginStatus, sessionToken };
