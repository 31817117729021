import { AxiosResponse } from 'axios';
import { GET, POST } from './http.service';

export type PaymentDTO = {
	reference: string;
	charges: string[];
}

//stage-api-portal.ontheappt.cloud?orgId=316835d9-1dbd-4dad-875a-44cd0ee8c773&siteId&role=2710a37a-194d-4e03-85ed-7ab626bf1111

export async function getChargesAndPayments(): Promise<AxiosResponse | unknown> {
  try {
    return await GET('/airtable/payment', {});
  } catch (e) {
    return e;
  }
}

export async function createPaymentFromCharges(paymentData: PaymentDTO): Promise<AxiosResponse | unknown> {
  try {
    return await POST('/airtable/payment', {}, paymentData);
  } catch (e) {
    return e;
  }
}

